import React from "react";
import {
  Box,
  Typography,
  IconButton,
  Avatar,
  AvatarGroup,
} from "@mui/material";
import RefreshLink from "./RefreshLink";
import AlbumArt from "./AlbumArt";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import InterpreterModeIcon from "@mui/icons-material/InterpreterMode";
import LockIcon from "@mui/icons-material/Lock";
import { colors } from "../entrypoints/lib/theme";

const AlbumList = ({ albums, title, handleMenuOpen, currentUser }) => (
  <Box sx={{ mb: 4 }}>
    <Typography variant="h5" sx={{ mb: 2 }}>
      {title}
    </Typography>
    {albums.length > 0 ? (
      albums.map((album) => (
        <Box
          key={album.id}
          sx={{
            mb: 2,
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: { xs: "center", sm: "flex-start" },
            justifyContent: "space-between",
            padding: { xs: 2, sm: 2 },
            borderRadius: 2,
            transition: "background-color 0.3s",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.04)",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: "center",
              width: "100%",
            }}
          >
            <RefreshLink to={`/album/${album.id}`}>
              <AlbumArt
                imageUrl={album.imageUrl}
                title={album.title}
                size={200}
              />
            </RefreshLink>
            <Box
              sx={{
                textAlign: { xs: "center", sm: "left" },
                width: { xs: "100%", sm: "auto" },
                ml: { xs: 0, sm: 2 },
                display: "flex",
                flexDirection: "column",
                alignItems: { xs: "center", sm: "flex-start" },
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {album.privacyLevel === 0 && (
                  <LockIcon
                    sx={{ mr: 1, fontSize: 20, color: colors.lightGray }}
                  />
                )}
                {album.privacyLevel === 1 && (
                  <InterpreterModeIcon
                    sx={{ mr: 1, fontSize: 20, color: colors.lightGray }}
                  />
                )}
                <RefreshLink to={`/album/${album.id}`}>
                  {album.title}
                </RefreshLink>
              </Typography>
              {album.creatorName && (
                <Typography variant="body2">
                  Created by:{" "}
                  <RefreshLink
                    sx={{ fontWeight: "bold" }}
                    to={`/user/${album.userId}`}
                  >
                    {album.creatorName}
                  </RefreshLink>
                </Typography>
              )}
              <Typography
                variant="body2"
                sx={{ color: colors.lightGray, mt: 0.5 }}
              >
                Updated {new Date(album.updatedAt.toDate()).toLocaleString()}
              </Typography>
              {album.sharedUsers && album.sharedUsers.length > 0 && (
                <>
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: "bold",
                      color: colors.lightGray,
                      mt: 0.5,
                    }}
                  >
                    Shared album
                  </Typography>
                  <AvatarGroup
                    spacing="small"
                    max={4}
                    sx={{ mt: 1, alignSelf: "flex-start" }}
                  >
                    {album.sharedUsers.map((user, index) => (
                      <>
                        {user && user.userId && (
                          <RefreshLink
                            to={
                              user && user.userId ? `/user/${user.userId}` : `#`
                            }
                            key={index}
                            style={{ marginLeft: index > 0 ? -16 : 0 }}
                          >
                            <Avatar
                              key={index}
                              alt={`${user?.firstName || ""} ${
                                user?.lastName || ""
                              }`}
                              src={user?.profilePhotoURL}
                              sx={{ width: 48, height: 48 }}
                              title={`${user?.firstName || ""} ${
                                user?.lastName || ""
                              }`}
                            >
                              {user?.firstName
                                ? user?.firstName[0]
                                : user?.email
                                ? user?.email[0].toUpperCase()
                                : "?"}
                            </Avatar>
                          </RefreshLink>
                        )}
                      </>
                    ))}
                  </AvatarGroup>
                </>
              )}
            </Box>
          </Box>
          {currentUser && currentUser.uid === album.userId && (
            <IconButton
              onClick={(event) => handleMenuOpen(event, album)}
              sx={{
                color: colors.navy,
                alignSelf: { xs: "flex-end", sm: "center" },
                mt: { xs: 1, sm: 0 },
              }}
            >
              <MoreVertIcon />
            </IconButton>
          )}
        </Box>
      ))
    ) : (
      <Typography variant="body1">No albums in this category.</Typography>
    )}
  </Box>
);

export default AlbumList;
